import { Lobby } from "@lobby/core/src/entities";
import { Button, LanguageSelector, useAuth, useModal } from "@lobby/core/src/shared";
import { useTranslate } from "@lobby/ocb-intl";
import { useNavigate } from "@tanstack/react-router";
import { RegisterForm } from "features/register-form";
import { useEffect } from "react";
import { Logo } from "shared/ui";
import { Modal } from "shared/ui/modal";

interface IModalClosable {
  isClosable?: boolean;
}

export function RegisterModal({ isClosable = true }: IModalClosable) {
  const { isOpen, close } = useModal("register");

  return (
    <Modal open={isOpen}>
      <Modal.Overlay onClick={isClosable ? close : undefined} />
      <RegisterModalComponent isClosable={isClosable} />
    </Modal>
  );
}

function RegisterModalComponent({ isClosable }: IModalClosable) {
  const { open: openLogInModal, close } = useModal("log-in");
  const { $t } = useTranslate();
  const navigate = useNavigate();

  const { isAuth } = useAuth();
  const { data: lobbyData } = Lobby.useLobby();

  const isRegistrationEnabled = lobbyData?.registration.enabled ?? false;

  useEffect(() => {
    if (isAuth || !isRegistrationEnabled) {
      navigate({
        to: "/",
        replace: true,
      });
    }
  }, [isAuth, isRegistrationEnabled, navigate]);

  return (
    <Modal.Panel className="mobile-only:min-h-full w-full lg:top-17 lg:w-[25rem]">
      {isClosable && (
        <Modal.CloseButton onClick={close} className="absolute right-2 lg:right-5 lg:hidden" />
      )}
      <Logo className="mx-auto h-20" />
      <div className="mt-5 flex-c-sb gap-5">
        <div className="font-bold text-14 uppercase lg:text-16">
          {$t({ defaultMessage: "Registration" })}
        </div>
        <Button variant="secondary" onClick={openLogInModal}>
          {$t({ defaultMessage: "sign in" })}
        </Button>
      </div>

      <div className="mt-7 mobile-only:mb-10">
        <RegisterForm />
      </div>

      <div className="mt-auto flex items-center justify-between">
        <LanguageSelector orientation="top" />
      </div>
    </Modal.Panel>
  );
}
