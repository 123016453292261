import { SupportChat } from "@lobby/core/src/components";
import { FortuneWheel } from "@lobby/core/src/entities";
import {
  Button,
  emitter as coreEmitter,
  isEmptyArray,
  isNil,
  useMobile,
} from "@lobby/core/src/shared";
import { useTranslate } from "@lobby/ocb-intl";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { emitter } from "app/config";
import { TakeBonusButton } from "features/take-bonus-button";
import { useEffect, useLayoutEffect, useMemo, useRef } from "react";
import { FloatGroup } from "shared/ui";
import { Footer } from "widget/footer";
import { FortuneWheel as FortuneWheelComponent } from "widget/fortune-wheel";
import type { TFortuneWheelSectorParams } from "widget/fortune-wheel";
import {
  type TSector,
  WHEEL_SECTORS_REPEAT_COUNT,
} from "widget/fortune-wheel/lib";
import { Header } from "widget/header";
import RulesSectionDesktop from "./rules-section.desktop";
import RulesSectionMobile from "./rules-section.mobile";

export function FortuneWheelPage() {
  const { $t } = useTranslate();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const isMobile = useMobile();

  const prevSectorsRef = useRef<TSector[]>([]);

  const { data, refetch } = FortuneWheel.useWheelSettings();

  const settings = data?.result;

  const sectors = useMemo(() => {
    // Prevent wheel sectors disappear when refetching
    if (!isNil(settings?.sectors) && !isEmptyArray(settings.sectors)) {
      prevSectorsRef.current = repeatSectors(
        settings.sectors,
        WHEEL_SECTORS_REPEAT_COUNT
      );
    }

    return prevSectorsRef.current;
  }, [settings]);

  function invalidateWheelState() {
    queryClient.invalidateQueries({ queryKey: ["Lobby.getCurrent"] });
    queryClient.invalidateQueries({ queryKey: ["FortuneWheel.getSettings"] });
  }

  function handleWinAnimationEnd(params: TFortuneWheelSectorParams) {
    emitter.emit("OPEN_CONGRATS_MODAL", {
      type: "fortune-wheel",
      winAmount: params.value,
      onOk: () => {
        invalidateWheelState();
        emitter.emit("CLOSE_CONGRATS_MODAL");
        navigate({ to: "/casino", replace: true });
      },
    });
  }

  function handleDepositAbsent() {
    coreEmitter.emit("WARNING", {
      message: $t({
        defaultMessage: "Make a deposit before spinning the wheel of fortune",
      }),
    });
  }

  function repeatSectors(sectors: TSector[], count: number) {
    const repeatedSectors = [...sectors];
    for (let i = 0; i < sectors.length * count; i++) {
      const sector = sectors[i % sectors.length];
      repeatedSectors.push({ ...sector, id: i * -1 });
    }

    return repeatedSectors;
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => () => void refetch(), []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useLayoutEffect(() => {
    if (settings && settings.state === "make_deposit") {
      handleDepositAbsent();
    }
  }, []);

  return (
    <div className="flex min-h-dvh flex-col bg-gradient-to-b from-6.5% from-cod-gray via-60% via-[#1E0053] to-[#6C00F0]">
      <Header />
      <main className="relative flex-grow overflow-hidden pb-4">
        <div className="mobile-only:absolute p-5">
          <Button
            variant="secondary"
            onClick={() => navigate({ to: "/casino" })}
          >
            {$t({ defaultMessage: "Back" })}
          </Button>
        </div>

        <div className="relative mt-12 mb-5 px-3 lg:mt-24 lg:flex-center lg:gap-15 lg:px-5">
          <div className="relative isolate mobile-only:mt-2 w-full flex-center">
            {!isMobile && <RulesSectionDesktop />}

            <FortuneWheelComponent
              sectors={sectors}
              onWinAnimationEnd={handleWinAnimationEnd}
              className="w-full mobile-only:max-w-96 lg:w-[50.625rem]"
            />
          </div>
        </div>

        {isMobile && (
          <div className="isolate w-full px-3">
            <RulesSectionMobile />
          </div>
        )}
      </main>
      <Footer className="mt-3" />

      <FloatGroup>
        <SupportChat className="z-10" />
        <TakeBonusButton />
      </FloatGroup>
    </div>
  );
}
