import { GameLists, SupportChat } from "@lobby/core/src/components";
import { FortuneWheel } from "@lobby/core/src/entities";
import { useMobile } from "@lobby/core/src/shared";
import { FortuneWheelButton } from "features/fortune-wheel-button";
import { GameSearch } from "features/game-search";
import { TakeBonusButton } from "features/take-bonus-button";
import { FloatGroup } from "shared/ui";
import { BannerPanelDesktop, BannerPanelMobile } from "widget/banner-panel";
import { CategoryList } from "widget/category-list";
import { Footer } from "widget/footer";
import { Header } from "widget/header";
import { Jackpots } from "widget/jackpots";
import { ProviderList, ProvidersModal } from "widget/provider-list";
import "./styles.css";

export function HomePage() {
  const isMobile = useMobile();
  const isDesktop = !isMobile;
  const isFortuneWheelAvailable = FortuneWheel.useIsWheelAvailable();

  return (
    <>
      <Header />
      <div className="absolute h-[12.5rem] w-full bg-[linear-gradient(180deg,#150221,#2D0656_5%,#180728_50%,#180023)]" />
      <main className="home-page__main relative space-y-3 p-2.5 lg:mx-auto lg:max-w-screen-3xl lg:space-y-5 lg:p-5">
        <ProvidersModal />

        {isDesktop ? <BannerPanelDesktop /> : <BannerPanelMobile />}

        <Jackpots />

        {isDesktop && (
          <div
            className="grid grid-cols-[18.5rem_minmax(0,auto)] items-start gap-5"
            id="navigate-category-anchor"
          >
            <CategoryList />
            <div className="grow space-y-5">
              <ProviderList />
              <GameLists />
            </div>
          </div>
        )}
        {isMobile && (
          <>
            <section>
              <GameSearch className="[&_input]:bg-cod-gray" />
            </section>

            {isFortuneWheelAvailable && <FortuneWheelButton.Mobile />}

            <ProviderList />
            <CategoryList />
            <GameLists id="navigate-category-anchor" />
          </>
        )}
      </main>
      <Footer />

      <FloatGroup>
        <SupportChat className="z-10" />
        <TakeBonusButton />
      </FloatGroup>
    </>
  );
}
