import { Lobby } from "@lobby/core/src/entities";
import { Button, LanguageSelector, emitter, useAuth, useModal } from "@lobby/core/src/shared";
import { useTranslate } from "@lobby/ocb-intl";
import { useNavigate } from "@tanstack/react-router";
import { LoginForm } from "features/log-in-form";
import { useEffect } from "react";
import { Logo } from "shared/ui/logo";
import { Modal } from "shared/ui/modal";

interface IModalClosable {
  isClosable?: boolean;
}

export function LogInModal({ isClosable = true }: IModalClosable) {
  const { isOpen, close } = useModal("log-in");

  useEffect(() => {
    const unbind = emitter.on("PLAYER_LOGIN", close);
    return unbind;
  }, [close]);

  return (
    <Modal open={isOpen}>
      <Modal.Overlay onClick={isClosable ? close : undefined} />
      <LogInModalComponent isClosable={isClosable} />
    </Modal>
  );
}

function LogInModalComponent({ isClosable }: IModalClosable) {
  const { open: openRegisterModal, close } = useModal("register");
  const navigate = useNavigate();
  const { $t } = useTranslate();

  const { isAuth } = useAuth();
  const { data: lobbyData } = Lobby.useLobby();

  const isRegistrationEnabled = lobbyData?.registration.enabled ?? false;

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (isAuth) {
      navigate({
        to: "/",
        replace: true,
      });
    }
  }, [isAuth]);

  return (
    <Modal.Panel className="mobile-only:min-h-full w-full lg:top-17 lg:w-[25rem]">
      {isClosable && (
        <Modal.CloseButton onClick={close} className="absolute right-2 lg:right-5 lg:hidden" />
      )}
      <Logo className="mx-auto h-20" />
      <div className="mt-5 flex-c-sb gap-5">
        <div className="font-bold text-14 uppercase lg:text-16">
          {$t({ defaultMessage: "Log In" })}
        </div>
        {isRegistrationEnabled && (
          <Button variant="secondary" onClick={openRegisterModal}>
            {$t({ defaultMessage: "Sign up" })}
          </Button>
        )}
      </div>

      <LoginForm className="mt-7 mobile-only:mb-10" />

      <div className="mt-auto flex items-center justify-between">
        <LanguageSelector orientation="top" />
      </div>
    </Modal.Panel>
  );
}
