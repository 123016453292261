import { type TJackpotsType, usePlayerMoneyFormatter } from "@lobby/core/src/entities";
import { Button, TextFitter } from "@lobby/core/src/shared";
import { useTranslate } from "@lobby/ocb-intl";
import { emitter } from "app/config";
import coinsBackImg from "assets/congrats-modal_coins-back.avif";
import coinsFrontImg from "assets/congrats-modal_coins-front.avif";
import coinsForWinAmountImg from "assets/congrats-modal_win-amount_coins.avif";
import { useEffect, useState } from "react";
import type { CongratsModalType, OpenCongratsModalEventPayload } from "shared/lib";
import { Modal } from "shared/ui/modal/modal";
import { getCongratsPrizeImage } from "../lib";

export function CongratsModal() {
  const [isOpened, setIsOpened] = useState(false);
  const [params, setParams] = useState<OpenCongratsModalEventPayload>();

  useEffect(() => {
    return emitter.on("OPEN_CONGRATS_MODAL", (payload) => {
      setParams(payload);
      setIsOpened(true);
    });
  }, []);

  useEffect(() => {
    return emitter.on("CLOSE_CONGRATS_MODAL", () => setIsOpened(false));
  }, []);

  return (
    isOpened &&
    params && (
      <CongratsModalView
        type={params.type}
        title={params.title}
        subtitle={params.subtitle}
        amount={params.winAmount}
        onOk={() => {
          setIsOpened(false);
          params.onOk?.();
        }}
        onCancel={() => {
          setIsOpened(false);
          params.onCancel?.();
        }}
      />
    )
  );
}

interface ICongratsModalProps {
  type: CongratsModalType;
  title?: string;
  subtitle?: string;
  amount: number;
  onOk: () => void;
  onCancel: () => void;
}

export function CongratsModalView({
  type,
  title,
  subtitle,
  amount,
  onOk,
  onCancel,
}: ICongratsModalProps) {
  const { $t } = useTranslate();
  const formatMoney = usePlayerMoneyFormatter();
  const isJackpot = type === "jackpot";

  const additionalSubtitle = isJackpot ? $t({ defaultMessage: "jackpot" }) : "";

  const jpType = (type === "jackpot" && subtitle ? subtitle.split(" ")[0] : null) as TJackpotsType;

  title ||=
    (type === "bonus" ? $t({ defaultMessage: "Bonus" }) : $t({ defaultMessage: "you won" })) + "!";

  subtitle ||= $t({ defaultMessage: "congrats" }) + "!";

  return (
    <Modal open>
      <Modal.Overlay />
      <Modal.Panel
        centered
        className="min-w-[21.25rem] max-w-full lg:w-[21.25rem] [&]:bg-transparent [&]:p-1"
      >
        <div className="paint-stroke -translate-x-1/2 relative left-1/2 w-[150%] max-w-full break-words pb-6 text-center font-bold text-[3.75rem]/none uppercase tracking-tight [-webkit-text-stroke:0.0625rem_#923EE3] [filter:drop-shadow(0_0_0.375rem_#B74AFF)]">
          {title}
        </div>

        <div className="rounded-10 border-1 border-mikado p-1 [background-image:linear-gradient(180deg,#181410_34.5%,#4F3F0C_41.5%,#FFEFAD_68.5%,#58460E)]">
          <div className="rounded-8 border-1 border-transparent bg-carnation bg-gradient-to-b from-[#2D0B46] from-[23%] via-[#DBC19D] via-[63%] to-[#2D0B46] to-[87%]">
            <div className="overflow-hidden rounded-inherit [background-image:linear-gradient(180deg,#000_14.5%,#46036C_50.5%,#7402B5_66.5%,#0E0328_82%)]">
              <div className="h-[18.625rem] pt-5">
                <div className="relative h-full">
                  <img
                    className="absolute bottom-0 w-full object-contain"
                    src={coinsBackImg}
                    alt="back coins"
                  />
                  <img
                    className="absolute bottom-0 max-h-full w-full object-contain"
                    src={getCongratsPrizeImage(type, jpType)}
                    alt=""
                  />
                  <img
                    className="absolute bottom-0 w-full object-contain"
                    src={coinsFrontImg}
                    alt="front coins"
                  />
                </div>
              </div>
              <hr className="border-[#997CFF]" />
              <div className="relative min-h-[8.625rem] px-5 pt-4 pb-13 [background-image:linear-gradient(90deg,#120037,#D399FF_1%,#A24FFC_4%,#570189_18%,#1C002F_50%,#570189_82%,#A24FFC_96%,#D399FF_99%,#120037)]">
                <img
                  className="absolute bottom-0 left-0 w-full object-contain"
                  src={coinsForWinAmountImg}
                  alt="coins for win amount"
                />
                <div className="flex h-full flex-col text-center font-bold uppercase leading-none tracking-tight [filter:drop-shadow(0_0.0625rem_0.125rem_#000)]">
                  <div className="paint-stroke break-words text-[1.5625rem] [-webkit-text-stroke:0.0625rem_#8E8E8E]">
                    {`${subtitle} ${additionalSubtitle}`}
                  </div>
                  <div className="flex-1 flex-center bg-golden-gradient bg-clip-text text-[2.8125rem] text-transparent [-webkit-text-stroke:0.0625rem_#FFCA58]">
                    <TextFitter className="mx-auto">{formatMoney(amount)}</TextFitter>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-center gap-2.5 pt-7.5">
          {type === "bonus" && (
            <Button variant="warning" onClick={onCancel}>
              {$t({ defaultMessage: "later" })}
            </Button>
          )}
          <Button variant="warning" onClick={onOk}>
            {$t({ defaultMessage: "take" })}
          </Button>
        </div>
      </Modal.Panel>
    </Modal>
  );
}
