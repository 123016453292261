import { FortuneWheel } from "@lobby/core/src/entities";
import { Game } from "@lobby/core/src/entities/game";
import {
  CategoryButton,
  ExternalIcon,
  clientCategories,
  range,
  useAuth,
  useMobile,
  useNavigateToCategory,
} from "@lobby/core/src/shared";
import { useTranslate } from "@lobby/ocb-intl";
import { useNavigate, useSearch } from "@tanstack/react-router";
import { FortuneWheelButton } from "features/fortune-wheel-button";
import { GameSearch } from "features/game-search";
import { useMemo } from "react";
import { Skeleton } from "shared/ui";

export function CategoryList() {
  const { $t } = useTranslate();
  const cid = useSearch({ strict: false, select: ({ cid }) => cid });
  const navigate = useNavigate();
  const navigateToCategory = useNavigateToCategory();

  const isMobile = useMobile();

  const { isAuth } = useAuth();
  const isFortuneWheelAvailable = FortuneWheel.useIsWheelAvailable();

  const { data: categoryList, isPending, isSuccess: isQuerySuccess } = Game.useCategoriesList();
  const categories = useMemo(
    () => categoryList?.filter(({ tag }) => tag === "menu" || !tag),
    [categoryList],
  );

  const isSuccess = isQuerySuccess && categories;

  return (
    <section>
      <div className="flex mobile-only:overflow-x-auto lg:flex-col">
        <GameSearch className="mobile-only:hidden [&_input]:bg-cod-gray" />

        {isFortuneWheelAvailable && !isMobile && <FortuneWheelButton.Desktop />}

        <ul className="flex lg:flex-col gap-2 lg:mt-5 [&_.category-btn]:mobile-only:flex-col [&_.category-btn]:mobile-only:justify-center [&_.category-btn]:mobile-only:h-full">
          <li>
            <CategoryButton
              iconName="allGames"
              isActive={!cid}
              onClick={() => navigateToCategory("allGames")}
            >
              {$t({ defaultMessage: "All Games" })}
            </CategoryButton>
          </li>

          {isAuth && (
            <>
              <li>
                <CategoryButton
                  isActive={String(cid) === clientCategories.favourites.id}
                  iconName="heart"
                  onClick={() => navigateToCategory(clientCategories.favourites.id)}
                >
                  {$t({ defaultMessage: "Favourites" })}
                </CategoryButton>
              </li>
              <li>
                <CategoryButton
                  isActive={String(cid) === clientCategories.recommended.id}
                  iconName="recommended"
                  onClick={() => navigateToCategory(clientCategories.recommended.id)}
                >
                  {$t({ defaultMessage: "Recommended" })}
                </CategoryButton>
              </li>
            </>
          )}

          {isPending &&
            range(8)((idx) => (
              <li key={idx}>
                <Skeleton className="h-15 w-full" />
              </li>
            ))}
          {isSuccess &&
            categories.map(({ id, name, actionType, actionData }) => {
              switch (actionType) {
                case "game":
                  return (
                    <li key={id}>
                      <CategoryButton
                        isActive={cid === id}
                        iconSlot={<ExternalIcon id={id} className="text-20" />}
                        onClick={() => {
                          navigate({
                            to: "/game/$gameId",
                            params: { gameId: actionData as string },
                          });
                        }}
                      >
                        {name}
                      </CategoryButton>
                    </li>
                  );
                default:
                  return (
                    <li key={id}>
                      <CategoryButton
                        isActive={cid === id}
                        iconSlot={<ExternalIcon id={id} className="text-20" />}
                        onClick={() => navigateToCategory(id)}
                      >
                        {name}
                      </CategoryButton>
                    </li>
                  );
              }
            })}
        </ul>
      </div>
    </section>
  );
}
