import { FortuneWheel, Lobby } from "@lobby/core/src/entities";
import {
  type TFortuneWheelSettings,
  formatTime,
  useFortuneWheelCountdown,
} from "@lobby/core/src/shared";
import { useTranslate } from "@lobby/ocb-intl";
import { Link } from "@tanstack/react-router";
import fortuneWheelBtnImg from "assets/fw-btn-bg.avif";
import { clsx } from "clsx";

export function FortuneWheelButton() {
  const { $t } = useTranslate();
  const { data: lobbyData } = Lobby.useLobby();
  const { data: settingsData } = FortuneWheel.useWheelSettings();

  const countdown = useFortuneWheelCountdown(lobbyData?.fortuneWheel?.nextSpinTime as number);

  const settings = settingsData?.result;
  const isCountdownActive = countdown > 0;

  function isDepositAbsent(settings: TFortuneWheelSettings) {
    return settings.state === "make_deposit";
  }

  if (settingsData?.error) {
    return null;
  }

  return (
    <div className="relative mt-5 h-[5.625rem] overflow-hidden bg-[linear-gradient(90deg,#2B003E,#440360_15%,#7A00AF_30%,#7A00AF_70%,#440360_85%,#2B003E)]">
      <div className="-translate-x-1/2 -translate-y-1/2 absolute top-1/2 left-1/2">
        <div className="size-36 animate-step-spin">
          <img
            className="object-contain"
            src={fortuneWheelBtnImg}
            alt="fortune-wheel-preview"
            width="100%"
            height="100%"
          />
        </div>
      </div>
      <Link
        className={clsx(
          "-translate-x-1/2 -translate-y-1/2 absolute top-1/2 left-1/2 min-w-44 rounded-rounded border-2 border-dark-charcoal px-2.5 py-1.5 no-underline [background:#000_radial-gradient(50%_100%_at_50%_50%,#55007B,#000_80%)] hover:text-inherit",
          !isCountdownActive && "hover:drop-shadow-[0_0_0.625rem_#FFBE01]",
        )}
        to="/fortune-wheel"
      >
        <div className="whitespace-nowrap text-center font-commissioner font-extrabold uppercase italic [text-shadow:0_0_1.5625rem_#6880FF]">
          {isCountdownActive ? (
            <div className="flex-center gap-1.5">
              <svg width="1em" height="1.125em" viewBox="0 0 18 20">
                <path
                  fill="#fff"
                  d="M9 20c4.606-.013 8.334-2.236 8.334-4.77 0-2.541-.464-5.882-.464-5.882-.188-.946-1.243-2.123-2.234-2.123V5.83C14.636 2.623 12.116.02 9 0 5.884.021 3.365 2.623 3.365 5.83v1.395c-.991 0-2.047 1.177-2.235 2.123 0 0-.463 3.34-.463 5.882C.667 17.764 4.394 19.987 9 20Zm1.023-6.97.317 2.61c0 .387-.36.7-.805.7H8.48c-.445 0-.805-.313-.805-.7l.37-2.61a1.943 1.943 0 0 1-.892-1.643c0-1.068.843-1.934 1.882-1.934 1.04 0 1.881.866 1.881 1.934 0 .694-.357 1.301-.892 1.642ZM6.191 5.657c0-1.6 1.255-2.9 2.81-2.918 1.553.018 2.808 1.317 2.808 2.918v1.324H6.191V5.658Z"
                />
              </svg>
              <span className="w-[4.375em] text-left">{formatTime(countdown)}</span>
            </div>
          ) : isDepositAbsent(settings ?? ({} as TFortuneWheelSettings)) ? (
            $t({ defaultMessage: "fortune wheel" })
          ) : (
            $t({ defaultMessage: "ready to spin" })
          )}
        </div>
      </Link>
      <hr className="absolute top-0 left-0 h-0.5 w-full border-none [background-image:linear-gradient(90deg,#2b013e,#82641D_40%,#FFFFFF_50%,#82641D_60%,#2b013e)]" />
      <hr className="absolute bottom-0 left-0 h-0.5 w-full border-none [background-image:linear-gradient(90deg,#2b013e,#82641D_40%,#FFFFFF_50%,#82641D_60%,#2b013e)]" />
    </div>
  );
}
