import logoImg from "assets/logo.avif";
import { clsx } from "clsx";
import type { HTMLAttributes } from "react";

export function Logo({ className, ...restProps }: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={clsx("brand-logo", className)} {...restProps}>
      <img src={logoImg} alt="logo" className="h-full object-contain" />
    </div>
  );
}
